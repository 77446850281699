import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'

import BlurredCta, { BlurredCtaProps } from '../UI/BlurredCta'
import { ArrowIcon } from '../UI/Icons'
import css from './styles.module.scss'

const cx = classnames.bind(css)

function SliderArrow({
  className,
  theme = GlobalThemeColors.Black,
  ...rest
}: BlurredCtaProps) {
  return (
    <BlurredCta
      theme={theme}
      className={cx(className, css.SliderArrow, theme)}
      {...rest}>
      <ArrowIcon className={cx(css.arrowIcon, css?.[theme])} />
    </BlurredCta>
  )
}

SliderArrow.defaultProps = {}

export default SliderArrow
