import classnames from 'classnames'
import dynamic from 'next/dynamic'
import { CSSProperties, ReactNode } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'

import {
  UnlikelyCustomAttributes,
  UnlikelyMoney,
} from '@unlikelystudio/commerce-connector'
import { useAddCartLines } from '@unlikelystudio/react-ecommerce-hooks'

import CtaWithPrice, { CtaWithPriceProps } from '~/components/CtaWithPrice'
import { useToast } from '~/components/Toast'
import BlurredCta from '~/components/UI/BlurredCta'

import { usePanel } from '~/providers/PanelProvider'

import { useUpdateCartAttributeProductTracking } from '~/hooks/cart/useUpdateCartAttributeProductTracking'

import { removeBlankAttributes } from '~/utils/remove-blank-attributes'

import { CART, CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

enum ProductState {
  Available = 'available',
  PreOrder = 'preOrder',
  OutOfStock = 'outOfStock',
  StockAlert = 'stockAlert',
}

interface ProductToAdd {
  id: string
  attributes?: UnlikelyCustomAttributes
}

interface BundleToAdd {
  merchandiseId: string
  attributes?: UnlikelyCustomAttributes
  customizations?: ProductToAdd[]
  labelCustomization?: ProductToAdd
}

const PanelCart = dynamic(() => import('~/components/Panels/CartPanelWrapped'))

export interface AddToCartProps {
  className?: string
  children?: ReactNode | ReactNode[]
  isSimpleProductPreOrder?: boolean
  upsale?: ProductToAdd
  merchandiseId: string
  bundles?: BundleToAdd[]
  rootMultiBundle?: ProductToAdd
  customAttributes?: UnlikelyCustomAttributes
  price: UnlikelyMoney
  isFullscreen?: boolean
  availableForSale?: boolean
  disabled?: boolean
  style?: CSSProperties
  loading?: boolean
  onSuccess?: () => void
  onClick?: () => void
  withStockAlert?: boolean
  theme?: CtaWithPriceProps['theme']
}

export interface UseAddToCartProps {
  withToast?: boolean
  onSuccess?: AddToCartProps['onSuccess']
}

export function useAddToCart({ withToast, onSuccess }: UseAddToCartProps) {
  const triggerToast = useToast()
  const t = useTranslate()
  const { add: addPanel } = usePanel()
  const { updateCartAttributeProductTracking } =
    useUpdateCartAttributeProductTracking()
  return useAddCartLines({
    onSuccess: ({ cart }, updatedItems, context) => {
      updateCartAttributeProductTracking(cart)
      onSuccess && onSuccess()
      if (withToast) {
        triggerToast({
          uid: 'add-to-cart',
          children: (
            <>
              {t(CART.PRODUCT_ADDED)}
              <BlurredCta
                className={css.cta}
                theme={GlobalThemeColors.Black}
                onClick={() =>
                  addPanel({
                    component: <PanelCart />,
                  })
                }>
                {t(CART.ORDER)}
              </BlurredCta>
            </>
          ),
        })
      } else {
        addPanel({
          component: <PanelCart />,
        })
      }
    },
  })
}

function AddToCart({
  className,
  upsale,
  price,
  isFullscreen,
  style,
  availableForSale,
  disabled,
  bundles,
  rootMultiBundle,
  children,
  onSuccess,
  onClick,
  withStockAlert,
  isSimpleProductPreOrder,
  theme,
  loading,
}: AddToCartProps) {
  const bundleProductPreOrder =
    bundles?.[0]?.customizations?.[0]?.attributes?.find(
      (attribute) => attribute.key === '_isBundleProductPreOrder',
    )?.value

  const isBundleProductPreOrder = bundleProductPreOrder === 'true'

  const { mutate, isLoading } = useAddToCart({
    withToast: false,
    onSuccess,
  })

  const t = useTranslate()

  const rootMultiBundleToAdd = rootMultiBundle
    ? {
        ...rootMultiBundle,
        quantity: 1,
      }
    : []

  const upsalesToAdd = upsale
    ? [
        {
          variantId: upsale.id,
          quantity: 1,
          ...(upsale.attributes
            ? {
                attributes: upsale.attributes.filter(
                  (attr) => attr.value !== '',
                ),
              }
            : {}),
        },
      ]
    : []

  const bundlesToAdd = bundles
    ? bundles
        .map((bundle) => {
          const mainProduct = {
            merchandiseId: bundle.merchandiseId,
            quantity: 1,
            attributes: removeBlankAttributes(bundle?.attributes),
          }
          const label = bundle?.labelCustomization
          const customizations = bundle?.customizations

          const labelCustomizationToAdd = label
            ? {
                merchandiseId: label.id,
                quantity: 1,
                ...(label.attributes
                  ? { attributes: removeBlankAttributes(label.attributes) }
                  : {}),
              }
            : null

          const customizationsToAdd = customizations
            ? customizations.map((customization) => ({
                merchandiseId: customization?.id,
                quantity: 1,
                ...(customization.attributes
                  ? {
                      attributes: removeBlankAttributes(
                        customization.attributes,
                      ),
                    }
                  : {}),
              }))
            : []

          return [
            mainProduct,
            labelCustomizationToAdd,
            ...customizationsToAdd,
          ].filter(Boolean)
        })
        .flat()
    : []

  const productsToAdd = []

    .concat(rootMultiBundleToAdd)
    .concat(upsalesToAdd)
    .concat(bundlesToAdd)

  const processProductState = () => {
    if (availableForSale) {
      if (isSimpleProductPreOrder || isBundleProductPreOrder) {
        return ProductState.PreOrder
      }
      return ProductState.Available
    } else {
      if (withStockAlert) {
        return ProductState.StockAlert
      }
      return ProductState.OutOfStock
    }
  }

  const productState = processProductState()
  return (
    <CtaWithPrice
      theme={theme}
      disabled={productState === ProductState.OutOfStock}
      className={cx(className, isLoading)}
      isLoading={isLoading || loading}
      style={style}
      isFullscreen={isFullscreen}
      onClick={() => {
        onClick?.()
        if (!withStockAlert && availableForSale) {
          mutate(productsToAdd)
        }
      }}
      price={availableForSale && !disabled ? price : null}>
      {(productState === ProductState.Available || loading) &&
        (children ?? t(CTA.ADD_TO_CART))}
      {productState === ProductState.OutOfStock && t(CTA.OUT_OF_STOCK)}
      {productState === ProductState.PreOrder && t(CTA.PRE_ORDER)}
      {productState === ProductState.StockAlert && t(CTA.STOCK_ALERT)}
    </CtaWithPrice>
  )
}

AddToCart.defaultProps = {}

export default AddToCart
