import classnames from 'classnames/bind'
import { useRef } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { TRACKING_EVENTS } from '~/lib/constants'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import SliderControls from '~/components/Abstracts/SliderControls'
import SliderArrows from '~/components/SliderArrows'
import { BlurredCtaColors } from '~/components/UI/BlurredCta'
import CardProduct, { CardProductProps } from '~/components/UI/CardProduct'

import { TriggerProductCardEventParams } from '~/hooks/tracking/useTrackProductCardsList'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  xxs: [{ breakpoint: 'md', ratio: 1 / 6 }, { ratio: 1 / 2 }],
  xs: [{ breakpoint: 'md', ratio: 0.125 }, { ratio: 1 / 2 }],
  s: [{ breakpoint: 'md', ratio: 1 / 4 }, { ratio: 1 / 3 }],
  m: [{ breakpoint: 'md', ratio: 1 / 4 }, { ratio: 1 / 2 }],
}
export interface SliderProductsProps {
  className?: string
  sliderClassName?: string
  layout?: 'm' | 's' | 'xs' | 'xxs'
  cardsAlign?: 'left' | 'center'
  smallCards?: boolean
  items?: CardProductProps[]
  withControls?: boolean
  withArrows?: boolean
  withCta?: boolean
  defaultCardProductProps?: CardProductProps
  isInFirstSlice?: boolean
  handleItemTracker: (params: TriggerProductCardEventParams) => void
  arrowsButtonLayout?: 's' | 'm'
  arrowsTheme: BlurredCtaColors
}

function SliderProducts({
  className,
  sliderClassName,
  items,
  withArrows,
  handleItemTracker,
  layout = 'm',
  smallCards = false,
  withControls,
  withCta,
  isInFirstSlice = false,
  defaultCardProductProps,
  arrowsButtonLayout = 'm',
  arrowsTheme = GlobalThemeColors.Black,
}: SliderProductsProps) {
  const customSliderRef = useRef()
  const [{ slideIndex, setSlideIndex, nextSlide, prevSlide }, setSliderState] =
    useSliderState()

  return (
    <div
      ref={customSliderRef}
      className={cx(css.SliderProducts, className, layout)}>
      <div className={css.sliderContainer}>
        <Slider
          className={cx(css.slider, sliderClassName)}
          customSliderRef={customSliderRef}
          autoIsSlider={false}
          snap
          setSliderState={setSliderState}
          infinite>
          {items?.map((item, index) => (
            <CardProduct
              {...defaultCardProductProps}
              isSmall={smallCards}
              withCta={withCta}
              sizesFromBreakpoints={IMAGE_SIZES[layout]}
              priority={
                isInFirstSlice
                  ? index < 1 / IMAGE_SIZES[layout]?.[0]?.ratio
                  : false
              }
              {...item}
              handleTracker={(params) => {
                if (handleItemTracker) {
                  handleItemTracker({
                    products: [item],
                    event: TRACKING_EVENTS.PRODUCT_CLICK,
                    listPosition: index,
                    ...params,
                  })
                }
              }}
              className={css.sliderItem}
              key={`slider_item_${index}`}
            />
          ))}
        </Slider>
        {withArrows && (
          <SliderArrows
            layout="inside"
            theme={arrowsTheme}
            buttonsLayout={arrowsButtonLayout}
            nextSlide={nextSlide}
            prevSlide={prevSlide}
          />
        )}
      </div>
      {withControls && (
        <div className={css.controls}>
          <SliderControls
            length={items?.length}
            className={css.sliderControls}
            slideIndex={slideIndex}
            setSlideIndex={setSlideIndex}
          />
        </div>
      )}
    </div>
  )
}

SliderProducts.defaultProps = {}

export default SliderProducts
