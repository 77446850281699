import classnames from 'classnames/bind'
import React, { useCallback } from 'react'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SliderControlsProps {
  className?: string
  buttonClassName?: string
  activeClassName?: string
  length?: number
  slideIndex?: number
  setSlideIndex?: (index: number) => void
}

export default function SliderControls({
  className,
  buttonClassName,
  length,
  setSlideIndex,
  slideIndex,
  activeClassName,
}: SliderControlsProps) {
  const indexes = [...Array(length).map(() => null)]

  const onClickIndex = useCallback(
    (index) => {
      setSlideIndex?.(index)
    },
    [setSlideIndex],
  )
  return length > 1 ? (
    <div className={cx(css.SliderControls, className)}>
      {indexes?.map((_, index) => (
        <span
          key={`sliderControl${index}`}
          onClick={() => onClickIndex(index)}
          className={cx(
            css.button,
            buttonClassName,
            slideIndex === index && activeClassName,
            { active: slideIndex === index },
          )}
        />
      ))}
    </div>
  ) : null
}

SliderControls.defaultProps = {}
