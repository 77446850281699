export function serializeCartTrackingData(trackingData) {
  const {
    item_id,
    item_brand,
    item_variant,
    price,
    discount,
    item_name: _name,
    item_product_id: _item_product_id,
    item_variant_id: _item_variant_id,
    quantity: _quantity,
    affiliation: _affiliation,
    description: _description,
    ...rest
  } = trackingData

  return {
    price,
    discount,
    item_variant,
    item_brand,
    item_id,
    ...rest,
  }
}
