import { UnlikelyCartLine } from '~/@types/cart'
import type { Nullish } from '~/@types/generic'
import { serializeCartTrackingData } from '~/lib/GTMTracking/serialize-cart-tracking-data'
import { SHOPIFY_CUSTOM_ATTRIBUTES } from '~/lib/constants'

import filterNoNullable from '~/utils/filter-no-nullable'

export function parseCartAttributesProductTrackingData(
  lines: Nullish<UnlikelyCartLine[]>,
) {
  return filterNoNullable(
    lines?.map((line) => getLineAttributesTrackingData(line)) ?? [],
  )
}

function getLineAttributesTrackingData(line: UnlikelyCartLine) {
  const trackingData =
    line?.attributes?.find(
      (att) => att.key === SHOPIFY_CUSTOM_ATTRIBUTES.trackingData,
    ) ?? null
  return trackingData?.value
    ? {
        id: line?.merchandise.id,
        trackingData: serializeCartTrackingData(
          JSON.parse(trackingData?.value),
        ),
      }
    : null
}
