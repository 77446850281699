import classnames from 'classnames/bind'
import { CSSProperties, ReactNode } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { UnlikelyMoney } from '@unlikelystudio/commerce-connector'

import { useStyle } from '~/providers/StyleProvider'

import useLocale from '~/hooks/useLocale'

import serializePrice from '~/data/serialize-price'

import Spinner from '../Abstracts/Spinner'
import Tag from '../Abstracts/Tag'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface CtaWithPriceProps {
  className?: string
  price: UnlikelyMoney
  disabled?: boolean
  children?: ReactNode | ReactNode[] | string
  isFullscreen?: boolean
  style?: CSSProperties
  isLoading?: boolean
  //@TODO remove any
  onClick?: any
  theme?: GlobalThemeColors.Shade3 | GlobalThemeColors.Black
}

function CtaWithPrice({
  className,
  price,
  isFullscreen,
  style,
  children,
  onClick,
  disabled,
  theme,
  isLoading,
  ...rest
}: CtaWithPriceProps) {
  const locale = useLocale()
  const textPreset = useStyle({
    textPreset: GlobalTextPreset.Title12Primary,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const priceStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12PrimaryReg,
  })

  return (
    <button
      {...rest}
      onClick={onClick}
      style={style}
      className={cx(css.CtaWithPrice, className, theme, {
        isFullscreen,
        isLoading,
        disabled,
      })}
      disabled={disabled}>
      {isLoading && (
        <span className={css.spinnerContainer}>
          <Spinner
            color={
              theme === GlobalThemeColors.Shade3
                ? GlobalThemeColors.Black
                : GlobalThemeColors.White
            }
            className={css.spinner}
          />
        </span>
      )}
      <span
        className={css.buttonLabel}
        style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        {children && (
          <span className={cx(css.addToCartText, textPreset)}>{children}</span>
        )}
        {price && (
          <Tag
            label={serializePrice(locale, price.currencyCode, price.amount)}
            theme={GlobalThemeColors.Shade1}
            labelColor={
              theme === GlobalThemeColors.Shade3
                ? GlobalThemeColors.Black
                : GlobalThemeColors.White
            }
            withBackground
            className={cx(css.price, priceStyle)}
          />
        )}
      </span>
    </button>
  )
}

CtaWithPrice.defaultProps = {}

export default CtaWithPrice
