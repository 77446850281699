import { UnlikelyCartLine } from '~/@types/cart'
import type { AllNullable, Nullish } from '~/@types/generic'
import { SHOPIFY_CUSTOM_ATTRIBUTES } from '~/lib/constants'

import { useUpdateCartAttributes } from '@unlikelystudio/react-ecommerce-hooks'

import { parseCartAttributesProductTrackingData } from '~/hooks/cart/useUpdateCartAttributeProductTracking/utils/cartAttributesProductTrackingData'
import useAlerts from '~/hooks/useAlerts'

export function useUpdateCartAttributeProductTracking() {
  const triggerAlert = useAlerts()
  const { mutate: updateCheckoutCustomAttribute } = useUpdateCartAttributes({
    onError: () => {
      triggerAlert()
    },
  })

  function updateCartAttributeProductTracking(
    cart: Nullish<
      AllNullable<{
        id: string
        lines: UnlikelyCartLine[]
      }>
    >,
  ) {
    if (cart?.id) {
      updateCheckoutCustomAttribute({
        cartId: cart.id,
        attributes: [
          {
            key: SHOPIFY_CUSTOM_ATTRIBUTES.trackingData,
            value: JSON.stringify(
              parseCartAttributesProductTrackingData(cart?.lines),
            ),
          },
        ],
      })
    }
  }

  return {
    updateCartAttributeProductTracking,
  }
}
