import { UnlikelyCustomAttributes } from '@unlikelystudio/commerce-connector'

export function removeBlankAttributes(
  attributes: UnlikelyCustomAttributes,
): UnlikelyCustomAttributes {
  if (!attributes) return []

  return attributes.filter(
    (attribute) => Boolean(attribute?.value) && attribute?.value !== '',
  )
}
