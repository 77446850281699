import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'

import { BlurredCtaColors } from '~/components/UI/BlurredCta'

import useBreakpoint from '~/hooks/useBreakpoint'

import numberAsBoolean from '~/utils/number-as-boolean'

import { SLIDER } from '~/data/dictionary'

import SliderArrow from '../SliderArrow'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SliderArrowsProps {
  prevSlide: () => void
  nextSlide: () => void
  layout?: 'inside' | 'outside'
  buttonsLayout?: 'xs' | 's' | 'm'
  theme?: BlurredCtaColors
  index?: number
  length?: number
  withChevronIcon?: boolean
  shouldHide?: boolean
}

function SliderArrows({
  prevSlide,
  nextSlide,
  layout,
  index,
  buttonsLayout = 'm',
  theme = GlobalThemeColors.Black,
  shouldHide,
  length,
}: SliderArrowsProps) {
  const t = useTranslate()
  return (
    <>
      {(!numberAsBoolean(index) || index > 0) && (
        <SliderArrow
          title={t(SLIDER.PREVIOUS)}
          aria-label={t(SLIDER.PREVIOUS)}
          onClick={prevSlide}
          theme={theme}
          className={cx(
            css.sliderArrow,
            css.leftArrow,
            css?.[`layout-${buttonsLayout}`],
            { [css.hide]: shouldHide },
            {
              [layout]: layout,
            },
          )}
        />
      )}
      {(!index || index < length - 1) && (
        <SliderArrow
          title={t(SLIDER.NEXT)}
          aria-label={t(SLIDER.NEXT)}
          onClick={nextSlide}
          theme={theme}
          className={cx(
            css.sliderArrow,
            css.rightArrow,
            css?.[`layout-${buttonsLayout}`],
            { [css.hide]: shouldHide },
            {
              [layout]: layout,
            },
          )}
        />
      )}
    </>
  )
}

SliderArrows.defaultProps = {}

export default SliderArrows
