import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useTranslate } from 'react-polyglot'
import { Card } from '~/@types/card'
import { GlobalThemeColors } from '~/@types/colors'
import { Variant } from '~/@types/product'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import {
  UnlikelyMoney,
  UnlikelyVariant,
} from '@unlikelystudio/commerce-connector'
import { useIsHover } from '@unlikelystudio/react-hooks'

import Link from '~/components/Abstracts/Link'
import Tag, { TagProps } from '~/components/Abstracts/Tag'
import AddToCart from '~/components/AddToCart'
import { useNavBannerContext } from '~/components/Navigation/Navigation/NavBanner'
import BlurredCta from '~/components/UI/BlurredCta'
import Image from '~/components/UI/Image'
import TagCustomizable from '~/components/UI/TagCustomizable'
import TagPreOrder from '~/components/UI/TagPreOrder'

import { useStyle } from '~/providers/StyleProvider'

import { TriggerProductCardEventParams } from '~/hooks/tracking/useTrackProductCardsList'
import useLocale from '~/hooks/useLocale'

import { CTA, PRODUCT } from '~/data/dictionary'
import serializePrice from '~/data/serialize-price'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_RATIOS = {
  NORMAL_WIDTH: 330 / 495,
  FULL_WIDTH: 375 / 630,
}

const IMAGE_SIZES = {
  NORMAL_WIDTH: [{ ratio: 330 / 495 }],
  FULL_WIDTH: [{ ratio: 6 / 6 }],
}

export interface CardProductData {
  name?: Card['name']
  price?: UnlikelyMoney
  compareAtPrice?: UnlikelyMoney
  priceFrom?: UnlikelyMoney
  shopifyId?: UnlikelyVariant['id']
  categories?: string[]
  meanings?: string[]
}

export interface CardProductProps extends Card, CardProductData {
  className?: string
  labels?: TagProps[]
  isFullWidth?: boolean
  isCustomizable?: boolean
  isSmall?: boolean
  date?: string
  hasMultipleVariants?: boolean
  defaultVariantId?: string
  defaultVariantTitle?: string
  defaultVariant?: Variant
  productName?: string
  location?: string
  withAddToCart?: boolean
  availableForSale?: boolean
  slug?: string
  priority?: boolean
  handleTracker?: (params?: Partial<TriggerProductCardEventParams>) => void
  isFullHeight?: boolean
  withCta?: boolean
  isSimpleProductPreOrder?: boolean
}

function CardProduct({
  className,
  slug,
  link,
  name,
  image,
  imageHover,
  labels,
  price,
  compareAtPrice,
  isFullWidth,
  isCustomizable,
  isSmall,
  defaultVariantId,
  hasMultipleVariants,
  withAddToCart = false,
  handleTracker,
  priority = false,
  sizesFromBreakpoints,
  isFullHeight = false,
  withCta,
  isSimpleProductPreOrder,
}: CardProductProps) {
  const locale = useLocale()
  const t = useTranslate()
  const [isHovered, callbacks] = useIsHover()

  const titleStyle = useStyle({
    textStyling: GlobalTextStyling.UpperCase,
    textPreset: withAddToCart
      ? GlobalTextPreset.Label10Primary
      : GlobalTextPreset.Label12Primary,
  })

  const priceStyle = useStyle({
    textPreset: GlobalTextPreset.Label12_10Secondary,
  })
  const [withBanner] = useNavBannerContext()
  const imgSizes = isSmall ? IMAGE_SIZES.FULL_WIDTH : IMAGE_SIZES.NORMAL_WIDTH

  const imgLayout = isFullWidth
    ? IMAGE_RATIOS.FULL_WIDTH
    : IMAGE_RATIOS.NORMAL_WIDTH

  const hasContent = name || price

  const renderCartBottom = () => (
    <div className={cx(css.cardBottom)}>
      {name && <h3 className={cx(css.cardLeft, titleStyle)}>{name}</h3>}
      {/* {price && !withAddToCart && (
      )} */}
      <div className={cx(css.cardRight, priceStyle)}>
        {hasMultipleVariants && <>{t(PRODUCT.PRICE_FROM)}&nbsp;</>}
        {compareAtPrice && (
          <del
            className={cx(
              css.compareAtPrice,
              GlobalTextPreset.Label12Secondary,
            )}>
            {serializePrice(
              locale,
              compareAtPrice?.currencyCode,
              compareAtPrice?.amount,
            )}
          </del>
        )}
        <Tag
          className={cx(css.priceTag)}
          theme={GlobalThemeColors.White}
          textPreset={GlobalTextPreset.Label12Secondary}>
          {serializePrice(locale, price?.currencyCode, price?.amount)}
        </Tag>
      </div>
    </div>
  )

  return (
    <div
      className={cx(css.CardProduct, className, {
        isFullWidth,
        isSmall,
        isHovered,
        withAddToCart,
        withBanner,
        isFullHeight,
      })}
      onClick={() => {
        if (handleTracker) handleTracker()
      }}
      {...callbacks}>
      <div className={css.cardContainer}>
        <div className={cx(css.cardImages)}>
          {!withAddToCart && (labels?.length > 0 || isCustomizable) && (
            <div className={css.cardTop}>
              <div className={css.cardRight}>
                {labels?.map((label, index) => (
                  <Tag
                    {...label}
                    theme={GlobalThemeColors.White}
                    className={css.label}
                    key={`card_label_${index}`}
                    textPreset={GlobalTextPreset.Label10Secondary}
                  />
                ))}
                {isCustomizable && (
                  <TagCustomizable className={css.label} isShrunk={isSmall} />
                )}
                {isSimpleProductPreOrder && (
                  <TagPreOrder className={css.label} isShrunk={isSmall} />
                )}
              </div>
            </div>
          )}
          {link && (
            <Link {...link} aria-label={`${name} - ${price}`}>
              {image && (
                <Image
                  className={cx(css.image)}
                  draggable={false}
                  priority={priority}
                  sizesFromBreakpoints={
                    sizesFromBreakpoints ? sizesFromBreakpoints : imgSizes
                  }
                  ratio={imgLayout}
                  layout="fill"
                  objectFit="cover"
                  asPlaceholder
                  {...image}
                />
              )}
              {imageHover && (
                <AnimatePresence>
                  {isHovered && (
                    <m.div
                      className={cx(css.hoverImage)}
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: { duration: 0.25 },
                      }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.25 },
                      }}>
                      <Image
                        sizesFromBreakpoints={
                          sizesFromBreakpoints ? sizesFromBreakpoints : imgSizes
                        }
                        ratio={imgLayout}
                        layout="fill"
                        objectFit="cover"
                        draggable="false"
                        {...imageHover}
                      />
                    </m.div>
                  )}
                </AnimatePresence>
              )}
            </Link>
          )}
        </div>
        {withAddToCart && defaultVariantId && (
          <AddToCart
            className={css.addToCart}
            price={price}
            theme={GlobalThemeColors.Shade3}
            availableForSale
            customAttributes={[
              {
                key: '_storyblok-slug',
                value: slug,
              },
            ]}
            merchandiseId={defaultVariantId}>
            {t(PRODUCT.VARIANT_ADD)}
          </AddToCart>
        )}
        {hasContent && link && (
          <Link {...link} className={css.cardLink}>
            {withCta && (
              <BlurredCta isDiv className={cx(css.cta)}>
                {t(CTA.PERSONALIZE)}
              </BlurredCta>
            )}
            {renderCartBottom()}
          </Link>
        )}
      </div>
    </div>
  )
}

CardProduct.defaultProps = {
  isFullWidth: false,
  isCustomizable: false,
  isSmall: false,
}

export default CardProduct
