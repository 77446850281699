import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import processEnvPublic from 'unlikely-env/public'
import { SIZE_VARIANT_KEY, TRACKING_EVENTS } from '~/lib/constants'

import { useTracker } from '~/providers/TrackerProvider'

import { ProductTrackingData } from '~/hooks/tracking'

import getAlternateUrl from '~/utils/alternate-url'
import numberAsBoolean from '~/utils/number-as-boolean'

interface ListProps {
  listName?: string
  listPosition?: number
}

export interface TriggerProductCardEventParams {
  products: ProductTrackingData[]
  event: string
  listPosition?: number
}

function generateIncrementalProperties(array: string[], property: string) {
  return array?.reduce((obj, value, index) => {
    if (index > 3) {
      return obj
    }

    const key = `item_${property}${index === 0 ? '' : index + 1}`
    return {
      ...obj,
      [key]: value,
    }
  }, {})
}

type IncrementalPropertiesCategories = 'category' | 'meaning'
type IncrementalPropertiesCategoriesTemplate =
  `item_${IncrementalPropertiesCategories}`
type IncrementalProperties = Partial<
  Record<
    | `${IncrementalPropertiesCategoriesTemplate}${number}`
    | IncrementalPropertiesCategoriesTemplate,
    string
  >
>

export type ProductTrackingObject = {
  item_name: string
  item_id: string
  price: number
  quantity: number
  item_brand: string
  brand: string
  item_variant: string
  item_size: string
  index?: number
  item_listName?: string
  personalized_label?: boolean
  compare_at_price: number
  image_url: string
  categories: string[]
  url: string
} & IncrementalProperties

export function fromProductCardPropsToProductTrackingData(
  product: ProductTrackingData,
  { listName, listPosition }: ListProps,
): ProductTrackingObject {
  const { categories, meanings, price, compareAtPrice } = product ?? {}
  const categoriesObject = generateIncrementalProperties(categories, 'category')
  const meaningsObject = generateIncrementalProperties(meanings, 'meaning')
  const defaultVariant = product?.defaultVariant
  const sizeOption =
    defaultVariant?.selectedOptions?.find(
      (opt) => opt?.name === SIZE_VARIANT_KEY,
    )?.value ?? null

  const imageUrl = product?.media?.[0]?.image?.src ?? null
  const url = getAlternateUrl(product?.link?.href?.toString() ?? null)

  return {
    item_name: product?.name ?? null,
    // now that the API returns unencoded gid strings, we need to base64encode our ids
    // before sending them to tracking
    item_id: defaultVariant?.sku,
    price: price?.amount ?? null,
    compare_at_price: compareAtPrice?.amount ?? null,
    image_url: imageUrl,
    url,
    ...categoriesObject,
    ...meaningsObject,
    categories,
    item_brand: processEnvPublic('NEXT_PUBLIC_PROJECT_NAME'),
    brand: processEnvPublic('NEXT_PUBLIC_PROJECT_NAME'),
    item_variant: defaultVariant?.sku ?? null,
    item_size: sizeOption ?? null,
    personalized_label: product?.personalizedLabel ?? false,
    ...(listName && {
      item_list_name: listName,
    }),
    ...(numberAsBoolean(listPosition) && {
      index: listPosition,
    }),
    quantity: product?.quantity ?? 1,
  }
}

export default function useTrackProductCardsList(
  listName: string,
  items: ProductTrackingData[],
) {
  const [setRef, inView] = useInView({ triggerOnce: true, rootMargin: '0px' })
  const tracker = useTracker()
  const formattedProducts = items?.map((item, index) => ({
    ...item,
    index,
  }))

  useEffect(() => {
    if (inView) {
      triggerEvent({
        products: formattedProducts,
        event: TRACKING_EVENTS.PRODUCT_IMPRESSION_LIST,
      })
    }
  }, [inView])

  const triggerEvent = ({ products, event }: TriggerProductCardEventParams) => {
    if (!products) return
    tracker.emit<typeof event>(
      event,
      products
        ?.filter((product) => product)
        ?.map?.((product, index) => {
          return fromProductCardPropsToProductTrackingData(product, {
            listPosition: index,
            listName,
          })
        }),
    )
  }

  return [setRef, triggerEvent] as const
}
